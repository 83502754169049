
.auto-complete{
	width: 49%
}

.auto-complete-wrapper{
	display: flex;
    justify-content: space-between;
	margin-bottom: 10px
}
